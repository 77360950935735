import { FC, useState, useRef, useMemo, FormEvent, SyntheticEvent, ChangeEvent } from 'react';
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    Tabs,
    Tab,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Popover,
    Alert,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useAuth } from '../../context/AppContext';
import axiosConfig from '../../utils/axiosConfig';
import useInterval from '../../utils/useInterval';

const Dashboard: FC = () => {
    const [ data, setData ] = useState<any>();
    const [ balance, setBalance ] = useState<any>();
    const [ open, setOpen ] = useState<any[]>([]);
    const [ history, setHistory ] = useState<any[]>([]);
    const [ tab, setTab ] = useState<number>(0);
    const [ error, setError ] = useState<boolean>(false);
    const [ message, setMessage ] = useState<string>('');
    const [ bot, setBot ] = useState<boolean>(false);
    const [ botFields, setBotFields ] = useState<string[]>([ '', '', '', '' ]);
    const botRefs = useRef<any[]>([]);
    const ordersRef = useRef<any>();

    const { authToken } = useAuth();

    const config = useMemo(() => ({
        headers: {
            'Authorization': `Bearer ${authToken}`
        }
    }), [authToken]);

    const getOrders = () => {
        axiosConfig.post('open', null, config).then(({ data }) => data?.code === 200 && setOpen(data.data));
        axiosConfig.post('history', null, config).then(({ data }) => data?.code === 200 && setHistory(data.data));
    };
    
    const order = (price: any, number: any, type: number) => axiosConfig.post('order', null, {
        params: {
            price: price,
            number: number,
            type: type
        },
        ...config
    }).then(({ data }) => {
        if (data?.code === 200) {
            getOrders();
        } else {
            setMessage(data?.msg || 'Unknown error.');
            setError(true);
            setTimeout(() => setError(false), 2000);
        }
    });

    const buy = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        const formData = new FormData(event.currentTarget);

        order(formData.get('vxxlPrice'), formData.get('vxxlNumber'), 1);
    };

    const sell = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        const formData = new FormData(event.currentTarget);

        order(formData.get('usdtPrice'), formData.get('usdtNumber'), 0);
    };

    const changeBot = (event: ChangeEvent<HTMLInputElement>) => {
        botFields[botRefs.current.indexOf(event.currentTarget)] = event.currentTarget.value;
        setBotFields([ ...botFields ]);
    };

    const toggleBot = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        const formData = new FormData(event.currentTarget);
        const range = formData.get('range');
        const min = formData.get('min');
        const max = formData.get('max');
        const orders = formData.get('orders');

        if (bot) {
            axiosConfig.post('stop-bot', null, config);
            setBot(false);
        } else if (range && min && max && orders) {
            axiosConfig.post('start-bot', null, {
                params: {
                    range: range,
                    min: min,
                    max: max,
                    orders: orders
                },
                ...config
            }).then(({ status }) => status === 200 && setBot(true));
        }
    };

    const parseTime = (time: number) => {
        const date = new Date(time);
        const hh = date.getHours().toString().padStart(2, '0');
        const mm = date.getMinutes().toString().padStart(2, '0');
        const ss = date.getSeconds().toString().padStart(2, '0');

        return `${hh}:${mm}:${ss}`;
    };

    const changeTab = (event: SyntheticEvent, newTab: number) => setTab(newTab);

    const depths: number[] = [];
    const openStatuses: number[] = [1, 4];

    useInterval(() => {
        axiosConfig.post('depth', null, config).then(({ status, data }) => status === 200 && setData(data));
        axiosConfig.post('balance', null, config).then(({ data }) => data?.code === 200 && setBalance(data.data));
        axiosConfig.get('status', config).then(({ data }) => {
            const inactive = !botRefs.current.includes(document.activeElement);

            if (data?.range && data.min && data.max && data.orders) {
                inactive && setBotFields([
                    data.range,
                    data.min,
                    data.max,
                    data.orders,
                    data.exception
                ]);
                setBot(true);
            } else {
                if (inactive && data?.exception !== botFields[4]) {
                    botFields[4] = data?.exception;
                    setBotFields([ ...botFields ]);
                }
                setBot(false);
            }
        });
        getOrders();
    }, 1000);

    return (
        <Grid container justifyContent="end">
            <Grid container item xs={8} justifyContent="center" alignItems="end" spacing={5}>
                <Grid item xs={5} style={{ paddingBottom: '10px' }}>
                    <Box component="form" onSubmit={buy} noValidate>
                        <Typography component="p">
                            Available: {balance?.usdt.available} USDT
                        </Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="vxxlPrice"
                            label="VXXL Price"
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    step: 0.000000001
                                }
                            }}
                            name="vxxlPrice"
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="vxxlNumber"
                            label="VXXL Amount"
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    step: 1
                                }
                            }}
                            name="vxxlNumber"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3 }}
                        >
                            Buy VXXL
                        </Button>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={5}
                    style={{
                        paddingTop: 0,
                        paddingBottom: '10px'
                    }}
                >
                    <Box component="form" onSubmit={sell} noValidate>
                        <Typography component="p">
                            Available: {balance?.vxxl.available} VXXL
                        </Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="usdtPrice"
                            label="USDT Price"
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    step: 0.000000001
                                }
                            }}
                            name="usdtPrice"
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="usdtNumber"
                            label="USDT Amount"
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    step: 0.00001
                                }
                            }}
                            name="usdtNumber"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3 }}
                        >
                            Sell VXXL
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={10} style={{ paddingTop: 0 }}>
                    <Typography component="p">
                        Bot: {(bot ? 'running' : 'stopped') + (botFields[4] ? ` with exception: ${botFields[4]}` : '')}
                    </Typography>
                    <Box component="form" onSubmit={toggleBot} noValidate display="flex" justifyContent="space-between">
                        <TextField
                            margin="normal"
                            id="range"
                            label="Range (%)"
                            type="number"
                            inputRef={(element) => botRefs.current[0] = element}
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    step: 0.1
                                }
                            }}
                            name="range"
                            value={botFields[0]}
                            disabled={bot}
                            sx={{ maxWidth: '6.5rem' }}
                            onChange={changeBot}
                        />
                        <TextField
                            margin="normal"
                            id="min"
                            label="Min Amount"
                            type="number"
                            inputRef={(element) => botRefs.current[1] = element}
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    step: 1
                                }
                            }}
                            name="min"
                            value={botFields[1]}
                            disabled={bot}
                            sx={{ maxWidth: '7rem' }}
                            onChange={changeBot}
                        />
                        <TextField
                            margin="normal"
                            id="max"
                            label="Max Amount"
                            type="number"
                            inputRef={(element) => botRefs.current[2] = element}
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    step: 1
                                }
                            }}
                            name="max"
                            value={botFields[2]}
                            disabled={bot}
                            sx={{ maxWidth: '7rem' }}
                            onChange={changeBot}
                        />
                        <TextField
                            margin="normal"
                            id="orders"
                            label="Orders/minute"
                            type="number"
                            inputRef={(element) => botRefs.current[3] = element}
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    max: 12,
                                    step: 1
                                }
                            }}
                            name="orders"
                            value={botFields[3]}
                            disabled={bot}
                            sx={{ maxWidth: '7.5rem' }}
                            onChange={changeBot}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                mt: 2,
                                mb: 1
                            }}
                        >
                            {bot ? 'Stop' : 'Start'}
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={11.95} style={{ paddingTop: 0 }}>
                    <Box ref={ordersRef}>
                        <Tabs value={tab} onChange={changeTab}>
                            <Tab label="Open Orders" />
                            <Tab label="Order History" />
                        </Tabs>
                        {tab ? (
                            <TableContainer component={Paper} style={{ height: '30vh' }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Time</TableCell>
                                            <TableCell align="center">Traded Price/Order Price</TableCell>
                                            <TableCell align="center">Volume/Order Qty</TableCell>
                                            <TableCell align="center">Туре</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {history.map((row: any, idx: number) => (
                                        <TableRow key={`row${idx}`}>
                                            <TableCell>{parseTime(row.time)}</TableCell>
                                            <TableCell align="center">{row.price}</TableCell>
                                            <TableCell align="center">{row.amount}</TableCell>
                                            <TableCell align="center" style={{ textTransform: 'uppercase' }}>
                                                {row.type ? 'Buy' : 'Sell'}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <TableContainer component={Paper} style={{ height: '30vh' }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Time</TableCell>
                                            <TableCell align="center">Traded Price/Order Price</TableCell>
                                            <TableCell align="center">Volume/Order Qty</TableCell>
                                            <TableCell align="center">Туре</TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    onClick={() => axiosConfig.post('cancel-all', {
                                                        params: {
                                                            ids: open.map((value: any) => value.id).join(',')
                                                        }
                                                    }, config).then(({ data }) => {
                                                        data?.code === 200 && getOrders();
                                                    })}
                                                >
                                                    Cancel All
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        open
                                            .sort((a, b) => (a.time < b.time) ? 1 : -1)
                                            .flatMap((row: any, idx: number) => openStatuses.includes(row.status) ? [(
                                                <TableRow key={`row${idx}`}>
                                                    <TableCell>{parseTime(row.time)}</TableCell>
                                                    <TableCell align="center">{row.price}</TableCell>
                                                    <TableCell align="center">{row.number}</TableCell>
                                                    <TableCell align="center" style={{ textTransform: 'uppercase' }}>
                                                        {row.type ? 'Buy' : 'Sell'}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => axiosConfig.post('cancel', null, {
                                                                params: {
                                                                    id: row.id
                                                                },
                                                                ...config
                                                            }).then(({ data }) => data?.code === 200 && getOrders())}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )] : [])
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ height: '100vh' }}>
                <TableContainer component={Paper} sx={{ height: 1/2 }}>
                    <Table sx={{ tableLayout: 'fixed' }} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Sell (Ask) Price</TableCell>
                                <TableCell>Sell (Ask) Qty</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Depth</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {data?.asks && data.asks.map((row: Array<number>, idx: number) => {
                            const value = row[0] * row[1];
                            
                            depths[idx] = value + (idx ? depths[idx - 1] : 0);

                            return (
                                <TableRow key={`row${idx}`}>
                                    <TableCell sx={{ overflow: 'hidden' }}>{row[0]}</TableCell>
                                    <TableCell sx={{ overflow: 'hidden' }}>{row[1]}</TableCell>
                                    <TableCell sx={{ overflow: 'hidden' }}>{parseFloat(value.toFixed(4))}</TableCell>
                                    <TableCell sx={{ overflow: 'hidden' }}>{parseFloat(depths[idx].toFixed(4))}</TableCell>
                                </TableRow>
                            )
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper} sx={{ height: 1/2 }}>
                    <Table sx={{ tableLayout: 'fixed' }} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Buy (Bid) Price</TableCell>
                                <TableCell>Buy (Bid) Qty</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Depth</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {data?.bids && data.bids.map((row: Array<number>, idx: number) => {
                            const value = row[0] * row[1];
                            depths[idx] = value + (idx ? depths[idx - 1] : 0);

                            return (
                                <TableRow key={`row${idx}`}>
                                    <TableCell sx={{ overflow: 'hidden' }}>{row[0]}</TableCell>
                                    <TableCell sx={{ overflow: 'hidden' }}>{row[1]}</TableCell>
                                    <TableCell sx={{ overflow: 'hidden' }}>{parseFloat(value.toFixed(4))}</TableCell>
                                    <TableCell sx={{ overflow: 'hidden' }}>{parseFloat(depths[idx].toFixed(4))}</TableCell>
                                </TableRow>
                            );
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Popover 
                open={error}
                anchorEl={ordersRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{ mt: -15 }}
            >
                <Box>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => setError(false)}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ minWidth: 210 }}
                    >
                        {message && message[0].toUpperCase() + message.slice(1)}
                    </Alert>
                </Box>
            </Popover>
        </Grid>
    );
};

export default Dashboard;
