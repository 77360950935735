import React, { FC, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import SignIn from './pages/SignIn/SignIn';
import Dashboard from './pages/Dashboard/Dashboard';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { AppContext } from './context/AppContext';

import './App.css';

const theme = createTheme({
    components: {
        MuiSwitch: {
            styleOverrides: {
                root: {
                    padding: '8px'
                },
                track: {
                    borderRadius: '11px',
                    backgroundColor: '#1976d2',
                    opacity: 0.7
                },
                switchBase: {
                    '&.Mui-checked': {
                        color: '#fff',
                        '& + .MuiSwitch-track': {
                            opacity: 0.7
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        }
                }
                }
            }
        }
    }
});

const App: FC = () => {
    const existingToken = sessionStorage.getItem('token');
    const parsedToken = existingToken && JSON.parse(existingToken);
    const [ authToken, setAuthToken ] = useState(parsedToken);
    
    const setToken = (data: string) => {
        sessionStorage.setItem('token', JSON.stringify(data));
        setAuthToken(data);
    };
  
    return (
        <AppContext.Provider value={{ authToken, setauthToken: setToken }}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                        <Route path="/signin" element={<SignIn />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </AppContext.Provider>
    );
};

export default App;
