import React, { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../../context/AppContext';

const PrivateRoute: FC<{ children: ReactElement }> = ({ children }) => {
    const { authToken } = useAuth();

    return authToken ? children : <Navigate to="/signin" />;
};

export default PrivateRoute;
